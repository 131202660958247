<template><div></div></template>

<script>
export default {
  created () {
    const {
      // eslint-disable-next-line camelcase
      access_token,
      userEmail,
      userId,
      userName,
      userCode,
      userPhone,
      language,
      timeZone,
      platformSource,
      path,
      ticketParams
    } = this.$route.query
    // WMS(Shipout)需要传递userId和userName作为ticket系统唯一的用户标识，并用于工单accout筛选
    const userInfo = {
      userEmail,
      userId,
      userName,
      userCode,
      userPhone,
      platformSource,
      language,
      timeZone
    }
    // eslint-disable-next-line camelcase
    if (access_token) {
      // eslint-disable-next-line camelcase
      localStorage.setItem('access_token', 'bearer ' + access_token)
      localStorage.setItem('userInfo', JSON.stringify(userInfo))
    }
    this.$nextTick(() => {
      const query = ticketParams ? JSON.parse(ticketParams) : {}
      this.$axios.post('/api/shipout-workorder/user/loginWorkOrder', userInfo).then(() => {
        this.$router.replace({ path: `/${platformSource}${path || '/list'}`, query })
      }).catch(error => {
        console.error(error)
        this.$router.replace({ path: `/${platformSource}${path || '/list'}`, query })
      })
    })
  }
}
</script>

<style scoped>

</style>
